import Brandon from '../assets/images/profile_pics/BDO_Profile.JPG';
import Andrew from '../assets/images/profile_pics/Andrew_Profile.jpg';
import Shi from '../assets/images/profile_pics/Shi_Profile.png';
import Gio from '../assets/images/profile_pics/Gio_Profile.jpg';
import Ken from '../assets/images/profile_pics/Ken_Profile.jpg';

const GIOVANNIM = {
	name: 'Giovanni Mogi',
	src: Gio,
	linkedin: 'https://www.linkedin.com/in/giovanni-mogi-189013193',
	github: 'https://github.com/GioMogi',
};

const BRANDONDO = {
	name: 'Brandon Do',
	src: Brandon,
	linkedin: 'https://www.linkedin.com/in/brandonndo/',
	github: 'https://github.com/brandonndo',
};

const KENIWANE = {
	name: 'Ken Iwane',
	src: Ken,
	linkedin: 'https://www.linkedin.com/in/ken-iwane-5b9209157/',
	github: 'https://github.com/keniwane',
};

const SHIKUANG = {
	name: 'Shi Kuang',
	src: Shi,
	linkedin: 'https://www.linkedin.com/in/shi-kuang/',
	github: 'https://github.com/shi-kuang',
};

const ANDREWWICKER = {
	name: 'Andrew Wicker',
	src: Andrew,
	linkedin: 'https://www.linkedin.com/in/andrewwicker/',
	github: 'https://github.com/andrew-wicker',
};

const arrTeam = [GIOVANNIM, BRANDONDO, ANDREWWICKER, SHIKUANG, KENIWANE];

export default arrTeam;
